import './App.css';
import * as React from 'react';
import { PriceDifference } from './components/reports/PriceDifference';
import { Box, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

function App() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="Summary" value="1" />
            <Tab label="Price Differences" value="2" />
            <Tab label="Price Drops" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <PriceDifference/>
        </TabPanel>
        <TabPanel value="2">Item Two</TabPanel>
        <TabPanel value="3">Item Three</TabPanel>
      </TabContext>
      </Box>
  );
}

export default App;
