import { Card, CardContent, Typography, CardActions, Button, CardMedia, Chip, Avatar, } from '@mui/material';
import React from 'react';
import styles from './Product.module.css';

class Product extends React.Component{
    constructor(props){
        super(props);
        this.records = props.data['records'].sort((a, b) => a.price < b.price ? -1 : a.price > b.price ? 1 : 0);
        this.cheapest_product = this.records[0];
        this.most_expensive_product = this.records[this.records.length-1];
        this.price_diff = Math.round(props.data['diff']*100)/100 //rounds to two decimal places;

        this.state = {loading: false};
    }
    render(){
        return <Card variant="outlined" sx={{ height:'400px'}} raised={true}>
            <CardContent>
                <Typography  variant="subtitle2" component="div">
                {this.cheapest_product['name']}    
                </Typography>
                <Typography sx={{ fontSize: 10}} color="text.secondary" gutterBottom>
                    {this.cheapest_product['brand']} - {this.cheapest_product['modelNumber']} 
                </Typography>
                <CardMedia
                    component="img"
                    image={this.cheapest_product['image']}
                    alt={this.cheapest_product['name']}
                />
                <Chip 
                    avatar={<Avatar>{this.cheapest_product['store'][0].toUpperCase()}</Avatar>} 
                    label={'$' + this.cheapest_product['price'] * 1.0}
                    component='a'
                    target="__blank"
                    href={this.cheapest_product['url']}
                    clickable={true}
                />
                <Chip avatar={<Avatar>{this.most_expensive_product['store'][0].toUpperCase()}</Avatar>} 
                label={'$' + this.most_expensive_product['price'] * 1.0}
                component='a'
                target="__blank"
                href={this.most_expensive_product['url']}
                clickable={true}/>
            </CardContent>
            
        </Card>
    }
}

export {Product};