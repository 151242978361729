import React from 'react';
import { Grid, Box, Chip, Paper,Skeleton, CardContent, Card,TextField, Stack } from '@mui/material';
import { Product } from '../Product/Product';
import { Search } from '../Product/Search';
import { padding } from '@mui/system';


export class PriceDifference extends React.Component{
    constructor(props){
        super(props);
        this.loading = false;
        this.state = { data: [], filters: new Set(), categories: new Set(), product_data:[], loading:false };
    }
    componentDidMount(){
        fetch("/data/pricediff.json")
        .then(res => res.json())
        .then(json => {this.setState({data: json},()=>{this.parseData();})});
    }
    componentDidUpdate(){

    }
    parseData(){
        var product_data =[]
        var categories = new Set();
        for(var i=0;i<this.state.data.length;i++){
            product_data.push(this.state.data[i]);
            product_data['hidden'] = true;
            categories.add(this.state.data[i]['category'].toLowerCase());
        }
        this.setState({product_data: product_data, categories: categories});
    }

    categoryFilter(filter){
        if(this.state.filters.has(filter)){
            this.state.filters.delete(filter);
        } else {
            this.state.filters.add(filter);
        }
        this.setState({filters: this.state.filters});

        var product_data_ns = this.state.product_data;

        for(var i=0;i<this.state.product_data.length;i++){
            var hide = this.state.filters.has(this.state.product_data[i]['category'].toLowerCase());
            product_data_ns[i]['hidden']=hide;
        }
        this.setState({product_data: product_data_ns});
    }
    search(searchTerm){
        console.log("searching " + searchTerm);

        searchTerm = searchTerm.toLowerCase();

        var product_data_ns = this.state.product_data;
        for(var i=0;i<this.state.product_data.length;i++){
            var records = this.state.product_data[i]['records'];
            var allWords = ""
            for(var j=0;j<records.length;j++){
                allWords += records[j]['name'];
                allWords += records[j]['brand'];
                allWords += records[j]['modelNumber'];
            }

            var wordBag = new Set(allWords.toLowerCase().split(" "));
            var hide = !Array.from(wordBag).join(' ').includes(searchTerm)
            product_data_ns[i]['hidden']= hide;
        }
        this.setState({product_data: product_data_ns});
    }
    render(){
        var rows = [];
        var filters = [];

        var categories = Array.from(this.state.categories);
        for(var index=0;index<categories.length;index++){
            const category = categories[index];
            filters.push(<Chip label={category} key={index} sx={{ textTransform: 'capitalize' }} 
            variant={ this.state.filters.has(category) ? 'outlined' : 'filled' } onClick={()=> this.categoryFilter(category) }  clickable={true}/>);
        }
        
        var show_limit = 100;
        var display_item_counter =0;
        for(var product_number=0;product_number<this.state.product_data.length;product_number++){
            if(!this.state.product_data[product_number]['hidden']){
                rows.push(<Grid item key={product_number} xs={2}><Product data={ this.state.product_data[product_number] } xs={{hidden:this.state.product_data[product_number].hidden}}/></Grid>);
                display_item_counter++;
                if(display_item_counter >= show_limit){
                    break;
                }
            }
        }
        
        
        return <Box>
            <TextField id="search-field"  fullWidth label="Search" type="search" onKeyUp={(e)=> this.search(e.target.value)}/>
         
            <Stack  sx={{padding: "1em"}}  direction="row" spacing={1}>{filters}</Stack>
            <Grid container spacing={2}>
                {rows}
            </Grid>
        </Box>
    }
}